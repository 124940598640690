define('fabbrikka-frontend/router', ['exports', 'ember', 'ember-router-scroll', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _emberRouterScroll, _fabbrikkaFrontendConfigEnvironment) {

  var Router = _ember['default'].Router.extend(_emberRouterScroll['default'], {
    location: _fabbrikkaFrontendConfigEnvironment['default'].locationType,
    rootURL: _fabbrikkaFrontendConfigEnvironment['default'].rootURL,
    metrics: _ember['default'].inject.service(),

    didTransition: function didTransition() {
      this._super.apply(this, arguments);
      this._trackPage();
    },

    _trackPage: function _trackPage() {
      var _this = this;

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (typeof FastBoot === 'undefined') {
          var page = document.location.pathname;
          var title = _this.getWithDefault('currentRouteName', 'unknown');
          _ember['default'].get(_this, 'metrics').trackPage({ page: page, title: title });
        }
      });
    }
  });

  Router.map(function () {
    this.route('products', function () {
      this.route('new');
      this.route('details', { path: '/:id/details' });
    });

    this.route('admin', function () {
      this.route('products', function () {
        this.route('new');
        this.route('edit', { path: '/:id/edit' });
      });

      this.route('product-audiences', function () {
        this.route('new');
      });

      this.route('product-variant-sizes', function () {
        this.route('new');
      });
      this.route('stock-items', function () {
        this.route('new');
      });
      this.route('faq', function () {
        this.route('new');
        this.route('edit', { path: '/:id/edit' });
      });
      this.route('faq-content');
    });
    this.route('shopping-cart', function () {
      this.route('checkout', function () {});
    });
    this.route('login');
    this.route('about');
    this.route('free-try-out-rules');
    this.route('faq');
  });

  exports['default'] = Router;
});