define('fabbrikka-frontend/routes/admin/products/edit', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model(params) {
            return _ember['default'].RSVP.hash({
                product: this.store.findRecord('product', params.id, { include: "product-images,product-variants,product-names,product-descriptions,product-variants.size" }),

                productAudiencesList: this.store.findAll('product-audience'),
                productSizesList: this.store.findAll('product-variant-size')
            });
        }
    });
});