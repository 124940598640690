define('fabbrikka-frontend/components/admin/product-images/new', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
  exports['default'] = _ember['default'].Component.extend({
    fileUploadHost: _fabbrikkaFrontendConfigEnvironment['default'].APP.backendHost,
    fileUploadEndpoint: _fabbrikkaFrontendConfigEnvironment['default'].APP.backendHost + "/files",

    imageUploadValidation: _ember['default'].computed(function () {
      var _this = this;

      return _ember['default'].run.bind(this, function () {
        if (_ember['default'].isEmpty(_this.productImageType)) {
          alert("please provide an image type");
          return false;
        }
        return true;
      });
    }),

    imageUploadFail: _ember['default'].computed(function () {
      return _ember['default'].run.bind(this, function (jqXHR, textStatus) {
        alert("image upload failed:" + textStatus);
      });
    }),

    imageUploadSuccess: _ember['default'].computed(function () {
      var _this2 = this;

      return _ember['default'].run.bind(this, function (data) {
        var url = _this2.fileUploadHost + data.links.self + "/download";
        _this2.sendAction('save', url, _this2.get('productImageType'));

        // this.createRecordLocally('productImages', 'product-image',
        //     {accessURL : this.fileUploadHost + data.links.self + "/download", type: this.productImageType});
      });
    })

  });
});
//clean up later