define("fabbrikka-frontend/locales/en-gb/translations", ["exports"], function (exports) {
  exports["default"] = {
    "components.navbar-mat-fabb.shop": "Shop",
    "components.navbar-mat-fabb.about": "About",
    "components.navbar-mat-fabb.free-try": "free try",

    "components.product-card.free-shipping": "Free shipping & return within europe",

    "component.overlay.upper": "sustainable fashion",
    "component.overlay.lower": "with respect for the world and its people",

    "component.title.upper": "Collection One",
    "component.title.lower": "Fur for people",

    "component.footer.tandc": "Terms &amp; Conditions",

    "components.buttons.back": "Back",

    "components.product-details.plusonesweater": "Added to cart",
    "components.product-details.choose-size": "Choose your size...",
    "components.product-details.add-to-cart": "Add to cart",
    "components.product-details.go-to-cart": "Go to cart",
    "components.product-details.choose-size-error": "Please select a size",
    "components.product-details.free-try-first": "I want a free try out (BE & NL only)",

    "component.sizetable.menu": "Size details",
    "component.sizetable.size": "Size",
    "component.sizetable.chest": "Chest",
    "component.sizetable.waist": "Waist",
    "component.sizetable.hip": "hip",

    "components.bancontact-payment.account-holders-name": "Card holder's name",
    "components.bancontact-payment.start-payment": "Start payment",
    "components.bancontact-payment.payment-ok-wait": "Ok, please hold on...",

    "components.sofort-payment.next": "proceed",

    "components.checkout-cart-overview.items-overview": "Click for item overview",
    "components.checkout-cart-overview.shipping": "Shipping",
    "components.checkout-cart-overview.free-try": "Free try outs (NL & BE)",
    "components.checkout-cart-overview.shipping-amount": "Free",
    "components.checkout-cart-overview.total": "Total",

    "components.credit-card-payment.submit": "Submit payment",

    "components.shopping-cart-overview-item.size": "Size",
    "components.shopping-cart-overview-item.amount": "Amount",
    "components.shopping-cart-overview-item.free-try": "free try",

    "components.shopping-cart-overview-row.size": "Size",
    "components.shopping-cart-overview-row.amount": "Amount",
    "components.shopping-cart-overview-row.free-try": "free try (BE & NL only)",

    "components.error-modal.the-error-message-is": "The error message is",
    "components.error-modal.if-you-dont-know": "If you are not sure how to fix the issue, you can always contact hello@fabbrikka.com. We're nice :)",

    "components.cookies-dialog.this-site-uses-cookies": "The EU asks us to tell you that our website uses cookies.",
    "components.cookies-dialog.for-more-info-click": "For more info on our delicious cookies, click",
    "components.cookies-dialog.here": "here",

    "components.voucher-payment.voucher-name": "voucher",
    "components.voucher-payment.voucher-code": "insert voucher code",
    "templates.application.headbanner": "SUSTAINABLE FASHION - FREE SHIPPING EUROPE",

    "templates.shopping-cart.checkout.checkout": "Checkout",
    "templates.shopping-cart.checkout.delivery-address": "Delivery address",
    "templates.shopping-cart.checkout.name": "Full name",
    "templates.shopping-cart.checkout.email": "E-mail",
    "templates.shopping-cart.checkout.street": "Street",
    "templates.shopping-cart.checkout.number-bus": "Number",
    "templates.shopping-cart.checkout.city-country": "City",
    "templates.shopping-cart.checkout.zip": "Zip",
    "templates.shopping-cart.checkout.country": "Country",
    "templates.shopping-cart.checkout.order-summary": "Order summary",
    "templates.shopping-cart.checkout.payment": "Payment - Powered by",
    "templates.shopping-cart.checkout.payment-prompt": "Please choose your payment method",
    "templates.shopping-cart.checkout.error-title": "Oooops, an error...",
    "templates.shopping-cart.checkout.order-confirmation.title": "Thank you for your order",
    "templates.shopping-cart.checkout.order-confirmation.order-id-title": "Your order ID",
    "templates.shopping-cart.checkout.order-confirmation.email-sent-to": "An e-mail has been sent to",
    "templates.shopping-cart.checkout.order-confirmation.in-case-of-issues": "In case of any issues, please contact",
    "templates.shopping-cart.checkout.order-confirmation.love-helping": "We love helping you",
    "templates.shopping-cart.checkout.order-confirmation.back": "Back to start",
    "templates.shopping-cart.checkout.choose-country": "Country",
    "templates.shopping-cart.checkout.country-not-in-list": "Any questions about your size, shipping to other countries or the weather:",
    "templates.shopping-cart.checkout.delivery-time": "You can expect your package within 5 business days.",
    "templates.shopping-cart.checkout.free-try-warning": "For free try outs, only credit card and delivery in BE and NL allowed.",

    "controllers.shopping-cart.countries.belgium": "Belgium",
    "controllers.shopping-cart.countries.spain": "Spain",
    "controllers.shopping-cart.countries.france": "France",
    "controllers.shopping-cart.countries.netherlands": "Netherlands",
    "controllers.shopping-cart.countries.germany": "Germany",
    "controllers.shopping-cart.countries.italy": "Italy",
    "controllers.shopping-cart.countries.uk": "United Kingdom",
    "controllers.shopping-cart.countries.austria": "Austria",
    "controllers.shopping-cart.countries.switzerland": "Switzerland",

    "templates.about.disclaimer": "Fabbrikka is owned by bvba Megenfelixendekat - Witte Patersstraat 4 - 1040 Brussels - Belgium - vat: BE0675410010",

    "templates.shopping-cart.index.title": "Shopping cart",
    "templates.shopping-cart.index.item": "Item",
    "templates.shopping-cart.index.size": "Size",
    "templates.shopping-cart.index.units": "Units",
    "templates.shopping-cart.index.subtotal": "Subtotal items",
    "templates.shopping-cart.index.shipping": "Shipping",
    "templates.shopping-cart.index.shipping-cost": "Free",
    "templates.shopping-cart.index.grand-total": "Grand total",
    "templates.shopping-cart.index.shop-more": "Shop more",
    "templates.shopping-cart.index.checkout": "Check out",
    "templates.shopping-cart.index.empty-cart": "Oooh, seems you haven't added any items... yet",

    "controllers.shopping-cart.index.errors.wrong-email": "Thanks for providing a valid e-mail :-)",
    "controllers.shopping-cart.index.errors.required": "Required...",

    "controllers.shopping-cart.checkout.errors.general": "The address contains errors...",
    "controllers.shopping-cart.checkout.payment.error-message": "Error processing payment: "
  };
});