define('fabbrikka-frontend/routes/admin/product-variant-sizes/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.createRecord('product-variant-size');
    },
    actions: {
      save: function save(model) {
        var self = this;
        return model.save().then(function () {
          self.transitionTo("admin.product-variant-sizes");
        })['catch'](function () {
          alert("Creation of product-variant-size failed");
        });
      },
      deactivate: function deactivate() {
        var model = this.controllerFor('admin.product-variant-sizes.new').get('model');
        model.rollbackAttributes();
      }
    }
  });
});