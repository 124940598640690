define('fabbrikka-frontend/routes/admin/faq/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.createRecord('faq').save();
    },

    afterModel: function afterModel(model) {
      this.transitionTo('admin.faq.edit', model.get("id"));
    }
  });
});