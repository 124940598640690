define('fabbrikka-frontend/components/locale-selector', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        localeTracker: _ember['default'].inject.service('locale-tracker'),
        tagName: "a",
        country: "gb",
        language: "en",

        id: _ember['default'].computed('elementId', function () {
            return this.get('elementId') + '-locale-selector';
        }),

        init: function init() {
            this._super.apply(this, arguments);
            this._setLocaleString(this.get("localeTracker").getLocale());
        },

        didInsertElement: function didInsertElement() {
            this.$(".locale-selector").dropdown({ belowOrigin: true });
        },

        actions: {
            setLocale: function setLocale(country, language) {
                this.set('country', country);
                this.set('language', language);
                this.get('localeTracker').setLocale(language.toLowerCase() + "-" + country.toLowerCase());
            }
        },

        _setLocaleString: function _setLocaleString(locale) {
            var split = locale.split("-");
            this.set("language", split[0]);
            this.set("country", split[1]);
        },

        _localeObserver: _ember['default'].observer("localeTracker.locale", function () {
            this._setLocaleString(this.get("localeTracker.locale"));
        })
    });
});