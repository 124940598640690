define('fabbrikka-frontend/components/cookies-dialog', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    cookies: _ember['default'].inject.service(),
    showDialog: false,

    _hasUserAcceptedCookies: function _hasUserAcceptedCookies() {
      return this.get('cookies').read('accepted-cookies') === 'true';
    },

    _storeAcceptCookies: function _storeAcceptCookies() {
      var expiration_date = new Date();
      expiration_date.setFullYear(expiration_date.getFullYear() + 1);
      this.get('cookies').write('accepted-cookies', 'true', { expires: expiration_date, path: "/" });
    },

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('showDialog', !this._hasUserAcceptedCookies());
    },

    actions: {
      acceptCookies: function acceptCookies() {
        this._storeAcceptCookies();
        this.set('showDialog', false);
      }
    }
  });
});