define("fabbrikka-frontend/controllers/faq", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    localeTracker: _ember["default"].inject.service(),
    locale: _ember["default"].computed.reads("localeTracker.locale"),
    filteredFaqEntries: _ember["default"].computed("locale", "model", function () {
      var _this = this;

      var entries = [];
      this.get("model").forEach(function (item) {
        item.get('faqContents').forEach(function (faqContent) {
          if (faqContent.get("locale") === _this.get('locale')) {
            entries.push(faqContent);
          }
        });
      });
      return entries;
    })

  });
});