define('fabbrikka-frontend/components/bancontact-payment', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        i18n: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        stripeService: _ember['default'].inject.service(),
        isSubmittingPayment: false,

        model: { accountHolderName: "" },
        errors: {},

        init: function init() {
            this._super.apply(this, arguments);
            this.set('model.accountHolderName', this.get('accountHolderName'));
        },

        didRender: function didRender() {
            if (this.get('clientSecret') && this.get('source') && !this.get('hasRendered')) {
                this.scrollToComponent();
                this.completePayement();
            }
            this.set('hasRendered', true);
        },

        //Validation stuff, literal copy paste from checkout. need to abstract this away...
        accountHolderNameEmptyObserver: _ember['default'].observer('model.accountHolderName', function () {
            this.validateEmptyField('accountHolderName');
        }),

        validateEmptyField: function validateEmptyField(key) {
            var hasErrors = false;
            var thisModel = this.get('model');

            if (!thisModel[key]) {
                this.set('errors.' + key, [this.get("i18n").t('controllers.shopping-cart.index.errors.required')]);
                hasErrors = true;
            } else {
                this.set('errors.' + key, []);
            }
            return hasErrors;
        },

        validateForm: function validateForm() {
            var hasErrors = false;
            var thisModel = this.get('model');
            var keys = Object.keys(thisModel);

            for (var i = 0; i < keys.length; i++) {
                hasErrors = this.validateEmptyField(keys[i]);
            }

            return hasErrors;
        },

        submitPaymentToPaymentService: function submitPaymentToPaymentService(tokenData) {
            tokenData['card'] = { 'brand': this.get('paymentType') };
            var checkoutData = { 'tokenData': { 'token': tokenData }, "billingData": this.get("billingData") };
            return this.get('ajax').request(_fabbrikkaFrontendConfigEnvironment['default'].APP.checkoutService + '/checkouts', {
                method: 'POST',
                data: JSON.stringify(checkoutData),
                contentType: 'application/vnd.api+json'
            });
        },

        completePayement: function completePayement() {
            var self = this;
            self.set('isSubmittingPayment', true);
            self.set('waitMessage', this.get('paymentType') + ' ' + this.get("i18n").t("components.bancontact-payment.payment-ok-wait"));
            self.get('onPay')().then(function (billingData) {
                self.set('billingData', billingData);
                return self.get('stripeService').finishPayment(self.get('source'), self.get('clientSecret'));
            }).then(self.submitPaymentToPaymentService.bind(self)).then(function (data) {
                self.get('stripeService').closeBCNPayment(self.get('source'));
                self.set('isSubmittingPayment', false);
                return self.get('onPaySuccess')(data);
            })['catch'](function (error) {
                self.get('stripeService').closeBCNPayment(self.get('source'));
                self.set('isSubmittingPayment', false);
                if (error && error["type"] === "tokenFetchError") {
                    return;
                }
                return self.get('onPayError')(error);
            });
        },

        scrollToComponent: function scrollToComponent() {
            _ember['default'].$('html, body').animate({
                scrollTop: _ember['default'].$(".bcn-button").offset().top
            }, 2000);
        },

        actions: {
            submitPayment: function submitPayment() {
                var self = this;
                self.set('isSubmittingPayment', true);
                self.get('onPay')().then(function (billingData) {
                    if (self.validateForm()) {
                        return _ember['default'].RSVP.Promise.reject({ "type": "bancontactFormIssue" });
                    }

                    billingData['paymentType'] = self.get("paymentType");

                    //TODO: this is a temporary workaround, the model should not be passed around through queries. But no time sorry
                    var redirectUrl = _fabbrikkaFrontendConfigEnvironment['default'].APP.publicHostName + _ember['default'].getOwner(self).lookup('controller:application').target.currentURL;
                    redirectUrl = redirectUrl + "?" + _ember['default'].$.param(billingData);

                    //set data object
                    var paymentData = {
                        type: self.get('paymentType'),
                        amount: Math.floor(self.get('totalAmount') * 100),
                        currency: 'eur',
                        owner: {
                            name: self.get('model.accountHolderName')
                        },
                        redirect: {
                            return_url: redirectUrl
                        }
                    };

                    return self.get('stripeService').initPayment(paymentData);
                })['catch'](function (error) {
                    self.set('isSubmittingPayment', false);
                    if (error && error["type"] === "bancontactFormIssue") {
                        return;
                    }
                    return self.get('onPayError')(error);
                });
            }
        }
    });
});