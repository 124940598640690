define('fabbrikka-frontend/routes/products/details', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Route.extend({
        fastboot: _ember['default'].inject.service(),
        headData: _ember['default'].inject.service(),

        beforeModel: function beforeModel() {
            return this.get('cart').setupCart();
        },

        model: function model(params) {
            return this.store.findRecord('product', params.id, { include: "product-images,product-variants,product-names,product-descriptions,product-variants.size" });
        },

        afterModel: function afterModel(model) {
            this._setupFacebookOG(model);
        },

        actions: {
            'delete': function _delete(product) {
                var _this = this;

                product.set('published', false);
                product.save().then(function () {
                    _this.transitionTo("admin.products");
                })['catch'](function () {
                    alert("Removal of product failed");
                });
            },

            edit: function edit(product) {
                this.transitionTo('admin.products.edit', product.get("id"));
            },

            back: function back() {
                history.back();
            }
        },

        _setupFacebookOG: function _setupFacebookOG(model) {
            if (this.get('fastboot.isFastBoot')) {
                var path = this.get('fastboot.request.path');
                this.set('headData.url', _fabbrikkaFrontendConfigEnvironment['default'].APP.publicHostName + path);
                var img = model.get("productImages").find(this._filterFacebookOGImage);
                if (img) {
                    this.set('headData.imageUrl', _fabbrikkaFrontendConfigEnvironment['default'].APP.publicHostName + img.get('accessURL'));
                }
            }
        },

        _filterFacebookOGImage: function _filterFacebookOGImage(productImage) {
            return productImage.get("type") === "facebook-share";
        }

    });
});