
if (typeof FastBoot === 'undefined') {
  if (!runningTests) {
    require('fabbrikka-frontend/app')['default'].create({"publicHostName":"http://playground.ruizdearcaute.com","backendHost":"","localeGuesser":"/fabbrikka-locale-guesser","cartService":"/fabbrikka-cart-service","checkoutService":"/fabbrikka-checkout-service","name":"fabbrikka-frontend","version":"0.3.1"});
  }
}

define('~fastboot/app-factory', ['fabbrikka-frontend/app', 'fabbrikka-frontend/config/environment'], function(App, config) {
  App = App['default'];
  config = config['default'];

  return {
    'default': function() {
      return App.create(config.APP);
    }
  };
});

