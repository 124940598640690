define('fabbrikka-frontend/components/checkout-cart-overview', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    localeTracker: _ember['default'].inject.service(),
    locale: _ember['default'].computed.reads("localeTracker.locale"),
    cartService: _ember['default'].inject.service('shopping-cart'),
    isFreeTryOutAllowed: _ember['default'].computed.reads('cartService.isFreeTryOutAllowed'),
    totalFreeTries: _ember['default'].computed.reads('cartService.totalFreeTries'),
    total: _ember['default'].computed.reads('cartService.total'),
    displayModalMaxFreeTriesReached: false,

    didInsertElement: function didInsertElement() {
      this.$('.collapsible').collapsible();
      this.set('displayModalMaxFreeTriesReached', false);
    },

    actions: {
      handleMaxFreeTriesReached: function handleMaxFreeTriesReached() {
        this.set("displayModalMaxFreeTriesReached", true);
      }
    }
  });
});