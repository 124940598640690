define('fabbrikka-frontend/components/product-card', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        session: _ember['default'].inject.service(),
        localeTracker: _ember['default'].inject.service(),
        locale: _ember['default'].computed.reads("localeTracker.locale"),
        sortedImages: _ember['default'].computed('item', function () {
            return this.get("item.productImages").toArray().sort(function (image) {
                return image.get("type") !== 'primary';
            });
        }),
        primaryImages: _ember['default'].computed.filterBy('item.productImages', 'type', 'primary'),
        primaryImageURL: _ember['default'].computed.oneWay('primaryImages.firstObject.accessURL'),
        productNames: _ember['default'].computed.reads('item.productNames'),
        name: _ember['default'].computed('locale', 'productNames', function () {
            var productName = this.get('productNames').find(function (e) {
                return e.get("locale") === this.get('locale');
            }, this);
            return !_ember['default'].isEmpty(productName) && productName.get("name");
        }),
        price: _ember['default'].computed('item.productVariants.firstObject', function () {
            var price = this.get('item.productVariants.firstObject.price');
            if (price) {
                return price + ' €';
            }
        })
    });
});