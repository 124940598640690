define("fabbrikka-frontend/locales/de/translations", ["exports"], function (exports) {
  exports["default"] = {
    "components.navbar-mat-fabb.shop": "Shop",
    "components.navbar-mat-fabb.about": "Über uns",
    "components.navbar-mat-fabb.free-try": "Gratis-Anprobe",

    "components.product-card.free-shipping": "Kostenloser Versand in Europa",

    "component.overlay.upper": "Nachhaltige Mode",
    "component.overlay.lower": "mit Respekt für die Welt und ihre Menschen",

    "component.title.upper": "Erste Kollektion",
    "component.title.lower": '"Fur for people"',

    "component.footer.tandc": "Allgemeine Geschäftsbedingungen",

    "components.buttons.back": "Zurück",

    "components.product-details.plusonesweater": "Im Warenkorb",
    "components.product-details.choose-size": "Wähle Deine Größe",
    "components.product-details.add-to-cart": "In den Warenkorb",
    "components.product-details.go-to-cart": "Zum Warenkorb",
    "components.product-details.choose-size-error": "Wähle Deine Größe",
    "components.product-details.free-try-first": "Ich möchte eine Gratis-Anprobe (derzeit nur in BE + NL verfügbar)",

    "component.sizetable.menu": "Größentabelle",
    "component.sizetable.size": "Größe",
    "component.sizetable.chest": "Brust",
    "component.sizetable.waist": "Taille",
    "component.sizetable.hip": "Hüfte",

    "components.bancontact-payment.account-holders-name": "Name des Karteninhabers",
    "components.bancontact-payment.start-payment": "Zahlung starten",
    "components.bancontact-payment.payment-ok-wait": "Ok, einen Moment bitte...",

    "components.sofort-payment.next": "Weiter",

    "components.checkout-cart-overview.items-overview": "Klicke hier, um Deine Artikel anzusehen",
    "components.checkout-cart-overview.shipping": "Versand",
    "components.checkout-cart-overview.free-try": "Gratis-Anprobe (BE + NL)",
    "components.checkout-cart-overview.shipping-amount": "Kostenlos",
    "components.checkout-cart-overview.total": "Gesamtpreis",

    "components.credit-card-payment.submit": "Jetzt bezahlen",

    "components.shopping-cart-overview-item.size": "Größe",
    "components.shopping-cart-overview-item.amount": "Anzahl",
    "components.shopping-cart-overview-item.free-try": "Gratis-Anprobe",

    "components.shopping-cart-overview-row.size": "Größe",
    "components.shopping-cart-overview-row.amount": "Anzahl",
    "components.shopping-cart-overview-row.free-try": "Gratis-Anprobe<br>(nur BE + NL)",

    "components.error-modal.the-error-message-is": "Die Fehlermeldung ist",
    "components.error-modal.if-you-dont-know": "Wenn Du nicht weißt, wie Du das Problem beheben kannst, kontaktiere uns einfach unter hello@fabbrikka.com. Wir sind ganz nett ;)",

    "components.cookies-dialog.this-site-uses-cookies": "Die EU verlangt von uns, dass wir Dich über die Cookies auf unserer Webseite informieren.",
    "components.cookies-dialog.for-more-info-click": "Für weitere Infos zu unseren leckeren Cookies, klick ",
    "components.cookies-dialog.here": "hier",

    "components.voucher-payment.voucher-name": "voucher",
    "components.voucher-payment.voucher-code": "please insert voucher code",

    "templates.application.headbanner": "NACHHALTIGE MODE - KOSTENLOSER VERSAND IN EUROPA",

    "templates.shopping-cart.checkout.checkout": "Bezahlung",
    "templates.shopping-cart.checkout.delivery-address": "Lieferadresse",
    "templates.shopping-cart.checkout.name": "Vor- und Nachname",
    "templates.shopping-cart.checkout.email": "E-Mail",
    "templates.shopping-cart.checkout.street": "Straße",
    "templates.shopping-cart.checkout.number-bus": "Hausnummer",
    "templates.shopping-cart.checkout.city-country": "Ort",
    "templates.shopping-cart.checkout.zip": "PLZ",
    "templates.shopping-cart.checkout.country": "Land",
    "templates.shopping-cart.checkout.order-summary": "Deine Bestellung",
    "templates.shopping-cart.checkout.payment": "Zahlung - Powered by",
    "templates.shopping-cart.checkout.payment-prompt": "Wähle Deine Zahlungsmethode",
    "templates.shopping-cart.checkout.error-title": "Uuups, ein Fehler...",
    "templates.shopping-cart.checkout.order-confirmation.title": "Danke für Deine Bestellung",
    "templates.shopping-cart.checkout.order-confirmation.order-id-title": "Deine Bestellnummer",
    "templates.shopping-cart.checkout.order-confirmation.email-sent-to": "Eine E-Mail wurde gesendet an",
    "templates.shopping-cart.checkout.order-confirmation.in-case-of-issues": "Bei Problemen, kontaktiere bitte",
    "templates.shopping-cart.checkout.order-confirmation.love-helping": "Wir helfen Dir gerne",
    "templates.shopping-cart.checkout.order-confirmation.back": "Zurück zum Start",
    "templates.shopping-cart.checkout.choose-country": "Land",
    "templates.shopping-cart.checkout.country-not-in-list": "Fragen zur Größe, Versand in andere Länder oder zum Wetter, kannst Du uns gerne per E-Mail senden an: ",
    "templates.shopping-cart.checkout.delivery-time": "Dein Paket wird in 5 Werktagen bei Dir eintreffen.",
    "templates.shopping-cart.checkout.free-try-warning": "Für Bestellungen mit Gratis-Anprobe akzeptieren wir derzeit nur Kreditkarten und Versand nach BE oder NL.",

    "controllers.shopping-cart.countries.belgium": "Belgien",
    "controllers.shopping-cart.countries.spain": "Spanien",
    "controllers.shopping-cart.countries.france": "Frankreich",
    "controllers.shopping-cart.countries.netherlands": "Niederlande",
    "controllers.shopping-cart.countries.germany": "Deutschland",
    "controllers.shopping-cart.countries.italy": "Italien",
    "controllers.shopping-cart.countries.uk": "Vereinigtes Königreich",
    "controllers.shopping-cart.countries.austria": "Österreich",
    "controllers.shopping-cart.countries.switzerland": "Schweiz",

    "templates.about.disclaimer": "Fabbrikka gehört der GmbH Megenfelixendekat - Witte Patersstraat 4 - 1040 Brüssel - Belgien - USt-IdNr: BE0675410010",

    "templates.shopping-cart.index.title": "Warenkorb",
    "templates.shopping-cart.index.item": "Artikel",
    "templates.shopping-cart.index.size": "Größe",
    "templates.shopping-cart.index.units": "Anzahl",
    "templates.shopping-cart.index.subtotal": "Zwischensumme",
    "templates.shopping-cart.index.shipping": "Versand",
    "templates.shopping-cart.index.shipping-cost": "Kostenlos",
    "templates.shopping-cart.index.grand-total": "Gesamtpreis",
    "templates.shopping-cart.index.shop-more": "Weiter shoppen",
    "templates.shopping-cart.index.checkout": "Zur Kasse",
    "templates.shopping-cart.index.empty-cart": "Oooh, es sieht so aus, als hättest Du noch keine Artikel hinzugefügt...",

    "controllers.shopping-cart.index.errors.wrong-email": "Bitte gib eine gültige E-Mail-Adresse an :-)",
    "controllers.shopping-cart.index.errors.required": "Pflichtfeld...",

    "controllers.shopping-cart.checkout.errors.general": "Die Adresse hat Fehler...",
    "controllers.shopping-cart.checkout.payment.error-message": "Fehler beim Zahlungsvorgang"
  };
});