define('fabbrikka-frontend/routes/admin/product-variant-sizes/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.findAll('product-variant-size');
    },

    actions: {
      'delete': function _delete(index) {
        this.store.findRecord('product-variant-size', index, { backgroundReload: false }).then(function (entry) {
          entry.destroyRecord();
        });
      }
    }
  });
});