define('fabbrikka-frontend/components/product-details', ['exports', 'ember'], function (exports, _ember) {
  // WORKAROUND: see https://github.com/mike-north/ember-materialize-shim/issues/80
  // import Materialize from 'materialize';

  exports['default'] = _ember['default'].Component.extend({
    session: _ember['default'].inject.service(),
    addToCartId: _ember['default'].computed('elementId', function () {
      return this.get('elementId') + '-addToCartId';
    }),
    i18n: _ember['default'].inject.service(),
    cartService: _ember['default'].inject.service('shopping-cart'),
    isFreeTryOutAllowed: _ember['default'].computed.reads('cartService.isFreeTryOutAllowed'),
    localeTracker: _ember['default'].inject.service(),
    locale: _ember['default'].computed.reads("localeTracker.locale"),

    productType: _ember['default'].computed.reads('data.type'),

    uniqueSizes: _ember['default'].computed.uniqBy('sizes', 'id'),
    selectedSizeId: _ember['default'].computed.reads('uniqueSizes.firstObject.id'),

    selectBoxDisplay: _ember['default'].computed('data.productVariants.@each.size', function () {
      return this.get('data.productVariants').map(function (item) {
        return { "id": item.get('id'), "name": item.get('size').get('name') };
      });
    }),

    isSmallScreen: _ember['default'].computed.reads('media.isS'),
    isGermanMobileDisplayComputed: _ember['default'].computed('locale', 'isSmallScreen', function () {
      if (this.get('locale') === 'de-de' && this.get('isSmallScreen')) {
        return true;
      }
      return false;
    }),

    isTryOut: false,
    maxFreeTriesReached: _ember['default'].computed.reads('cartService.maxFreeTriesReached'),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.get('productType') !== 'voucher') {
        this._setupSizeToolTip();
      }
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$('select').material_select(); //see issue https://github.com/mike-north/ember-cli-materialize/issues/434
    },

    images: _ember['default'].computed('data.@each.productImages', function () {
      return this.get("data.productImages").filter(function (e) {
        return e.get("type") === "primary" || e.get("type") === "detail";
      });
    }),

    sortedImages: _ember['default'].computed('images', function () {
      return this.get("images").toArray().sort(function (image) {
        return image.get("type") !== 'primary';
      });
    }),

    productNames: _ember['default'].computed.reads('data.productNames'),
    name: _ember['default'].computed('locale', 'productNames', function () {
      var productName = this.get('productNames').find(function (e) {
        return e.get("locale") === this.get('locale');
      }, this);
      return !_ember['default'].isEmpty(productName) && productName.get("name");
    }),

    price: _ember['default'].computed('selectedVariantId', function () {
      var selectedVariantId = this.get('selectedVariantId');
      if (!selectedVariantId) {
        return this.get('data.productVariants.firstObject.price') + '€';
      }

      var variant = this.get('data.productVariants').find(function (i) {
        return i.get('id') === selectedVariantId;
      });

      return variant.get('price') + '€';
    }),

    productDescriptions: _ember['default'].computed.reads('data.productDescriptions'),
    description: _ember['default'].computed('locale', 'productDescriptions', function () {
      var description = this.get('productDescriptions').find(function (e) {
        return e.get("locale") === this.get('locale');
      }, this);
      return !_ember['default'].isEmpty(description) && description.get("description");
    }),

    toolTipMessage: _ember['default'].observer('selectedVariantId', function () {
      var selectedVariantId = this.get('selectedVariantId');
      if (selectedVariantId) {
        this._destroySizeToolTip();
      }
    }),

    _setupSizeToolTip: function _setupSizeToolTip() {
      var message = this.get("i18n").t('components.product-details.choose-size-error');
      this.$("#" + this.get('addToCartId')).tooltip({ position: "top", tooltip: message, delay: 0 });
    },

    _destroySizeToolTip: function _destroySizeToolTip() {
      this.$("#" + this.get('addToCartId')).tooltip('remove');
    },

    actions: {
      addToCart: function addToCart() {
        var _this = this;

        if (!this.get('selectedVariantId') && this.get('productType') !== 'voucher') {
          return;
        }

        if (this.get('productType') == 'voucher') {
          this.set('selectedVariantId', this.get('data.productVariants.firstObject.id'));
        }

        this.get('cartService').addItem(this.get('selectedVariantId'), 1, this.get('isTryOut')).then(function () {
          var thanksText = _this.get("i18n").t('components.product-details.plusonesweater');
          Materialize.toast(thanksText, 2000, 'rounded');
          _this.$('.detail-go-to-cart').addClass('scale-in');
        })['catch'](function () {
          return alert('Oeps, je kan maximum 4 stuks gratis passen...');
        });
      }
    }

  });
});