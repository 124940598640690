define('fabbrikka-frontend/components/shopping-cart-overview-item', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    localeTracker: _ember['default'].inject.service(),
    locale: _ember['default'].computed.reads("localeTracker.locale"),
    tagName: 'tr',
    classNames: [],
    cartService: _ember['default'].inject.service('shopping-cart'),
    product: _ember['default'].computed.reads('item.productVariant.product'),
    primaryImages: _ember['default'].computed.filterBy('product.productImages', 'type', 'primary'),
    productNames: _ember['default'].computed.reads('product.productNames'),
    name: _ember['default'].computed('locale', 'productNames', function () {
      if (!this.get('productNames') || !this.get('locale')) {
        return;
      }
      var productName = this.get('productNames').find(function (e) {
        return e.get("locale") === this.get('locale');
      }, this);
      return !_ember['default'].isEmpty(productName) && productName.get("name");
    }),

    productType: _ember['default'].computed.reads('item.productVariant.product.type'),
    isFreeTryOutAllowed: _ember['default'].computed('cartService.isFreeTryOutAllowed', 'productType', function () {
      if (this.get('cartService.isFreeTryOutAllowed') && this.get('productType') !== 'voucher') {
        return true;
      }
      return false;
    }),

    isTryOut: _ember['default'].computed.reads('item.isTryOut'),

    image: _ember['default'].computed.reads('primaryImages.firstObject.accessURL'),
    size: _ember['default'].computed.reads('item.productVariant.size.id'),
    quantity: _ember['default'].computed.reads('item.quantity'),
    productVariants: _ember['default'].computed.reads('product.productVariants'),
    sizes: _ember['default'].computed.mapBy('productVariants', 'size'),
    availibleSizes: _ember['default'].computed.uniqBy('sizes', 'id'),

    isPageReady: _ember['default'].computed('availibleSizes', function () {
      if (!this.get('availibleSizes') || this.get('availibleSizes').length === 0) {
        return false;
      }
      return true;
    }),

    selectVariantBySize: function selectVariantBySize(productVariants, sizeId) {
      return productVariants.find(function (e) {
        return e.get('size').get('id') === sizeId;
      });
    },

    quantityValidator: _ember['default'].observer('quantity', function () {
      this.set('validationError', []);
      var x = parseFloat(this.get('quantity'));
      var isNumber = !isNaN(x) && (x | 0) === x && x > 0;
      if (!isNumber) {
        this.set('validationError', ['wrong']);
      }
    }),

    didRender: function didRender() {
      this._super.apply(this, arguments);
      this.$('select').material_select();
    },

    actions: {
      'delete': function _delete(id) {
        this.get('cartService').removeItem(id)['catch'](function () {
          return alert('issue deleting item...');
        });
      },
      updateSize: function updateSize(sizeId) {
        var selectedVariant = this.selectVariantBySize(this.get('productVariants'), sizeId);
        this.set('size', sizeId);
        this.get('cartService').updateItem(this.get('item.id'), selectedVariant.get('id'), this.get('quantity'), this.get('isTryOut'))['catch'](this._handleItemUpdateError.bind(this));
      },
      updateQuantity: function updateQuantity() {
        var selectedVariant = this.selectVariantBySize(this.get('productVariants'), this.get('size'));
        this.get('cartService').updateItem(this.get('item.id'), selectedVariant.get('id'), this.get('quantity'), this.get('isTryOut'))['catch'](this._handleItemUpdateError.bind(this));
      },
      updateIsTryOut: function updateIsTryOut() {
        var selectedVariant = this.selectVariantBySize(this.get('productVariants'), this.get('size'));
        this.get('cartService').updateItem(this.get('item.id'), selectedVariant.get('id'), this.get('quantity'), this.get('isTryOut'))['catch'](this._handleItemUpdateError.bind(this));
      }
    },

    _handleItemUpdateError: function _handleItemUpdateError(error) {
      var _this = this;

      if (error === "maxFreeTriesReached") {
        this.sendAction('handleMaxFreeTriesReached');
        this.get('cartService').getItem(this.get('item.id')).then(function (item) {
          _this.set('quantity', item.get('quantity'));
          _this.set('isTryOut', item.get('isTryOut'));
        });
        return;
      }
      alert("General error, try again or contact hello@fabbrikka.com");
    }
  });
});