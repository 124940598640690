define('fabbrikka-frontend/routes/shopping-cart/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      return this.get('cart').setupCart();
    },

    actions: {
      back: function back() {
        history.back();
      }
    }
  });
});