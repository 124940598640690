define('fabbrikka-frontend/components/items-grid', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({

        _formatRows: function _formatRows(data) {
            var rows = [],
                start = 0,
                stop = 4,
                size = 4,
                length = data.get('length');

            while (start < length + size - 1) {
                rows.push(data.slice(start, stop));
                start = start + size;
                stop = stop + size;
            }

            return rows;
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.set("rows", this._formatRows(this.get('data')));
        }
    });
});