define('fabbrikka-frontend/routes/admin/product-audiences/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.findAll('product-audience');
    },

    actions: {
      'delete': function _delete(index) {
        this.store.findRecord('product-audience', index, { backgroundReload: false }).then(function (productAudience) {
          productAudience.destroyRecord();
        });
      }
    }
  });
});