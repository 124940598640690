define("fabbrikka-frontend/controllers/admin/faq/edit", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    locales: [{ "value": "en-gb" }, { "value": "nl-be" }, { "value": "de-de" }],
    faqContents: _ember["default"].computed.reads('model.faqContents'),

    actions: {
      save: function save() {
        var _this = this;

        var faqContentEntry = this.store.createRecord('faq-content', {
          locale: this.get('locale'),
          question: this.get('question'),
          response: this.get('response')
        });
        this.get("model").get("faqContents").pushObject(faqContentEntry);
        return faqContentEntry.save().then(function () {
          return _this.get("model").save();
        });
      },

      "delete": function _delete(index) {
        this.store.findRecord('faq-content', index, { backgroundReload: false }).then(function (entry) {
          entry.destroyRecord();
        });
      }
    }
  });
});