define('fabbrikka-frontend/helpers/bw-compat-icon', ['exports', 'ember-cli-materialize/helpers/bw-compat-icon'], function (exports, _emberCliMaterializeHelpersBwCompatIcon) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _emberCliMaterializeHelpersBwCompatIcon['default'];
    }
  });
  Object.defineProperty(exports, 'bwCompatIcon', {
    enumerable: true,
    get: function get() {
      return _emberCliMaterializeHelpersBwCompatIcon.bwCompatIcon;
    }
  });
});