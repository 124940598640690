define('fabbrikka-frontend/routes/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        preserveScrollPosition: true,
        model: function model() {
            return this.store.query('product', {
                include: "product-names,product-images",
                backgroundReload: true,
                filter: {
                    published: true
                }
            }).then(function (products) {
                return products.sortBy('ranking');
            });
        }
    });
});