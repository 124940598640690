define('fabbrikka-frontend/services/shopping-cart', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({
        localeTracker: _ember['default'].inject.service(),
        fastboot: _ember['default'].inject.service(),
        store: _ember['default'].inject.service('store'),
        ajax: _ember['default'].inject.service(),
        cart: null,
        totalObserver: _ember['default'].observer('cart.shoppingCartItems.@each.quantity', 'cart.shoppingCartItems.@each.productVariant', function () {
            _ember['default'].run.once(this, this._setTotals);
        }),
        total: 0,
        isFreeTryOutAllowed: _ember['default'].computed("localeTracker.countryCode", function () {
            //we work with black-list. This is a safer path, as if no decent locale is provided
            //then we have fallback
            var blackList = ['de'];
            var countryCode = this.get("localeTracker.countryCode");
            if (blackList.indexOf(countryCode) > -1) {
                return false;
            }
            return true;
        }),

        totalFreeTries: 0,
        maxFreeTries: 4,
        totalItems: 0,
        maxFreeTriesReached: false,
        isFreeTryCountry: true,

        getItem: function getItem(id) {
            return this.get('store').findRecord('shopping-cart-item', id, { include: 'product-variant' });
        },

        addItem: function addItem(id, quantity, isTryOut) {
            var self = this;
            return self.get('store').findRecord('product-variant', id).then(function (item) {
                var shoppingCartItem = self.get('store').createRecord('shopping-cart-item', { "quantity": quantity,
                    "productVariant": item,
                    "shoppingCart": self.get('cart'),
                    "isTryOut": isTryOut || false
                });

                if (self._isMaxFreeTriesReached(shoppingCartItem)) {
                    shoppingCartItem.rollbackAttributes();
                    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                        reject("maxFreeTriesReached");
                    });
                }

                return shoppingCartItem.save();
            });
        },

        updateItem: function updateItem(id, variantId, quantity, isTryOut) {
            var self = this;
            return _ember['default'].RSVP.all([self.get('store').findRecord('shopping-cart-item', id), self.get('store').findRecord('product-variant', variantId)]).then(function (items) {
                items[0].set("quantity", quantity);
                items[0].set("productVariant", items[1]);
                items[0].set("isTryOut", isTryOut || false);

                if (self._isMaxFreeTriesReached(items[0])) {
                    items[0].rollbackAttributes();
                    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                        reject("maxFreeTriesReached");
                    });
                }

                return items[0].save();
            });
        },

        removeItem: function removeItem(id) {
            return this.get('store').findRecord('shopping-cart-item', id, { backgroundReload: false }).then(function (item) {
                return item.destroyRecord();
            });
        },

        setupCart: function setupCart() {
            var self = this;
            if (self.get('fastboot.isFastBoot')) {
                console.log("INFO: setupCart disabled in fastboot modus...");
                return; //we don't need it here
            }

            if (_ember['default'].isNone(self.get('cart'))) {
                return self._tryFetchAssociatedCart().then(function (id) {
                    if (!id) {
                        return self._createNewCart();
                    }
                    return self.get('store').findRecord('shopping-cart', id, { include: "shopping-cart-items,shopping-cart-items.product-variant" });
                }).then(function (cart) {
                    self.set('cart', cart);
                    self._associateCart(cart);
                    return cart;
                });
            }
            return _ember['default'].RSVP.Promise.resolve(self.get('cart'));
        },

        resetCart: function resetCart() {
            var self = this;
            self.get("store").unloadAll('shoppingCart');
            self.get("store").unloadAll('shoppingCartItem');
            self.set("cart", null);
            return self.setupCart();
        },

        _createNewCart: function _createNewCart() {
            var self = this;
            var cart = self.get('store').createRecord('shopping-cart');
            return cart.save();
        },

        _associateCart: function _associateCart(cart) {
            //links cart with session
            return this.get('ajax').request(_fabbrikkaFrontendConfigEnvironment['default'].APP.cartService + '/shopping-carts', {
                method: 'PATCH',
                data: JSON.stringify(cart.serialize({ includeId: true })),
                contentType: 'application/vnd.api+json'
            });
        },

        _tryFetchAssociatedCart: function _tryFetchAssociatedCart() {
            //fetch associated cart with session remotly
            return this.get('ajax').request(_fabbrikkaFrontendConfigEnvironment['default'].APP.cartService + '/shopping-carts').then(function (data) {
                return data[0];
            });
        },

        _isMaxFreeTriesReached: function _isMaxFreeTriesReached(item) {
            if (!item.get("isTryOut") || !this.get('cart')) {
                return false;
            }
            var items = this.get('cart').get('shoppingCartItems');
            items.addObject(item);
            var totals = this._getTotals(items);
            if (totals.totalFreeTries > this.get('maxFreeTries')) {
                return true;
            }
            return false;
        },

        _getTotals: function _getTotals(items) {
            var totals = { total: 0, totalItems: 0, totalFreeTries: 0 };
            items.map(function (item) {
                var quantity = parseInt(item.get('quantity'));
                var price = parseFloat(item.get('productVariant.price'));
                totals.totalItems += quantity;
                if (item.get('isTryOut')) {
                    totals.totalFreeTries += quantity;
                    return;
                }
                totals.total += price * quantity;
            });
            return totals;
        },

        _setTotals: function _setTotals() {
            if (!this.get('cart')) {
                return; //no cart set, do nothing
            }
            var items = this.get('cart').get('shoppingCartItems');
            var totals = this._getTotals(items);
            this.set('total', totals.total);
            this.set('totalItems', totals.totalItems);
            this.set('totalFreeTries', totals.totalFreeTries);
            this.set('maxFreeTriesReached', totals.totalFreeTries >= this.get('maxFreeTries'));
        }
    });
});