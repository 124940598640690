define('fabbrikka-frontend/components/file-upload', ['exports', 'ember', 'ember-uploader'], function (exports, _ember, _emberUploader) {
  exports['default'] = _emberUploader['default'].FileField.extend({
    filesDidChange: function filesDidChange(files) {
      var uploader = _emberUploader['default'].Uploader.create({
        url: this.get('url')
      });

      if (!this.get('validationAction')()) {
        return;
      }

      if (!_ember['default'].isEmpty(files)) {
        uploader.upload(files[0]).then(this.get('uploadSuccessAction'), this.get('uploadFailedAction'));
      }
    }
  });
});