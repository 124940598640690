define('fabbrikka-frontend/controllers/admin/products/edit', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Controller.extend({
        fileUploadHost: _fabbrikkaFrontendConfigEnvironment['default'].APP.backendHost,
        fileUploadEndpoint: _fabbrikkaFrontendConfigEnvironment['default'].APP.backendHost + "/files",
        localesList: [{ "value": "en-gb" }, { "value": "nl-be" }, { "value": "de-de" }],
        productTypesList: [{ "value": "sweater" }, { "value": "pants" }, { "value": "shirt" }],
        imageTypesList: [{ "value": "primary" }, { "value": "detail" }, { "value": "facebook-share" }],

        productDescriptions: _ember['default'].computed.reads('data.productDescriptions'),

        actions: {
            updateProduct: function updateProduct() {
                var self = this;
                this.get('store').findRecord('product', this.get('model.product.id')).then(function (product) {
                    product.set("type", self.get('model.product.type'));
                    product.set("ranking", self.get('model.product.ranking'));
                    product.set('published', true);
                    product.save();
                });
            },

            addName: function addName(locale, name) {
                var record = this.get('store').createRecord('product-name', { locale: locale, name: name });
                this.get('model.product.productNames').pushObject(record);
                return record.save();
            },

            deleteName: function deleteName(id) {
                this.get('store').findRecord('product-name', id, { backgroundReload: false }).then(function (record) {
                    record.destroyRecord();
                });
            },

            addDescription: function addDescription(productDescriptionLocale, productDescription) {
                var record = this.get('store').createRecord('product-description', { locale: productDescriptionLocale, description: productDescription });
                this.get('model.product.productDescriptions').pushObject(record);
                return record.save();
            },

            deleteDescription: function deleteDescription(id) {
                this.get('store').findRecord('product-description', id, { backgroundReload: false }).then(function (description) {
                    description.destroyRecord();
                });
            },

            addVariant: function addVariant(sizeId, price) {
                var size = this.store.peekRecord('product-variant-size', sizeId);
                var record = this.get('store').createRecord('product-variant', { size: size, price: price });
                this.get('model.product.productVariants').pushObject(record);
                return record.save();
            },

            deleteVariant: function deleteVariant(id) {
                this.get('store').findRecord('product-variant', id, { backgroundReload: false }).then(function (item) {
                    item.destroyRecord();
                });
            },

            addAudience: function addAudience(id) {
                var audience = this.store.peekRecord('product-audience', id);
                this.get('store').findRecord('product', this.get('model.product.id')).then(function (product) {
                    product.get("productAudiences").pushObject(audience);
                    product.save();
                });
            },

            deleteAudience: function deleteAudience(id) {
                this.get('store').findRecord('product-audience', id, { backgroundReload: false }).then(function (item) {
                    item.destroyRecord();
                });
            },

            addImage: function addImage(url, imageType) {
                var record = this.get('store').createRecord('product-image', { accessURL: url, type: imageType });
                this.get('model.product.productImages').pushObject(record);
                return record.save();
            },

            deleteImage: function deleteImage(id) {
                this.get('store').findRecord('product-image', id, { backgroundReload: false }).then(function (item) {
                    item.destroyRecord();
                });
            }
        }
    });
});