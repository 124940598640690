define('fabbrikka-frontend/models/product', ['exports', 'ember-data'], function (exports, _emberData) {
    exports['default'] = _emberData['default'].Model.extend({
        type: _emberData['default'].attr('string'),
        ranking: _emberData['default'].attr('number'),
        published: _emberData['default'].attr('boolean', { defaultValue: true }),
        productNames: _emberData['default'].hasMany('product-name', { inverse: 'product' }),
        productDescriptions: _emberData['default'].hasMany('product-description', { inverse: 'product' }),
        productImages: _emberData['default'].hasMany('product-image', { inverse: 'product' }),
        productVariants: _emberData['default'].hasMany('product-variant', { inverse: 'product' }),
        productAudiences: _emberData['default'].hasMany('product-audience', { inverse: 'products' })
    });
});