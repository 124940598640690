define('fabbrikka-frontend/routes/admin/products/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.query('product', {
                reload: true
            }).then(function (products) {
                return products.sortBy('ranking');
            });
        }
    });
});