define('fabbrikka-frontend/components/error-modal', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        toggleOpen: _ember['default'].observer('status', function () {
            if (this.get('status')) {
                this.$("#" + this.get("id")).modal('open');
                return;
            }
            this.$("#" + this.get("id")).modal('close');
        }),

        id: _ember['default'].computed('elementId', function () {
            return this.get('elementId') + '-error-modal';
        }),

        didInsertElement: function didInsertElement() {
            var self = this;
            this._initModal(function () {
                self.set('status', false);
            });
        },

        _initModal: function _initModal(callback) {
            this.$('#' + this.get('id')).modal({
                dismissible: true, // Modal can be dismissed by clicking outside of the modal
                opacity: 0.5, // Opacity of modal background
                inDuration: 300, // Transition in duration
                outDuration: 200, // Transition out duration
                startingTop: '4%', // Starting top style attribute
                endingTop: '10%',
                complete: callback
            });
        },

        willDestroyElement: function willDestroyElement() {
            this._super.apply(this, arguments);
            this.set('status', false);
            this._initModal(null); //hackish...
            this.$('#' + this.get('id')).modal('close');
        },

        actions: {
            onAgree: function onAgree() {
                this.set('status', false);
            }
        }
    });
});