define('fabbrikka-frontend/controllers/shopping-cart/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    localeTracker: _ember['default'].inject.service(),
    locale: _ember['default'].computed.reads("localeTracker.locale"),
    cartService: _ember['default'].inject.service('shopping-cart'),
    isFreeTryOutAllowed: _ember['default'].computed.reads('cartService.isFreeTryOutAllowed'),
    totalFreeTries: _ember['default'].computed.reads('cartService.totalFreeTries'),
    total: _ember['default'].computed.reads('cartService.total'),
    hasItems: _ember['default'].computed.reads('cartService.totalItems'),
    displayModalMaxFreeTriesReached: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('displayModalMaxFreeTriesReached', false);
    },

    actions: {
      handleMaxFreeTriesReached: function handleMaxFreeTriesReached() {
        this.set("displayModalMaxFreeTriesReached", true);
      }
    }

  });
});