define("fabbrikka-frontend/locales/nl/translations", ["exports"], function (exports) {
  exports["default"] = {

    "components.navbar-mat-fabb.shop": "Winkel",
    "components.navbar-mat-fabb.about": "Over ons",
    "components.navbar-mat-fabb.free-try": "gratis passen",

    "components.product-card.free-shipping": "Gratis levering binnen Europa",

    "component.overlay.upper": "duurzame kleiding",
    "component.overlay.lower": "met respect voor mens en omgeving",

    "component.title.upper": "Collectie één",
    "component.title.lower": '"Fur for people"',

    "component.footer.tandc": "Algemene Voorwaarden",

    "components.buttons.back": "Terug",

    "components.product-details.plusonesweater": "Toegevoegd aan mandje",
    "components.product-details.choose-size": "Kies een maat",
    "components.product-details.add-to-cart": "Toevoegen",
    "components.product-details.go-to-cart": "Naar mandje",
    "components.product-details.choose-size-error": "Gelieve een maat te kiezen",
    "components.product-details.free-try-first": "Ik wil gratis passen",

    "component.sizetable.menu": "Maattabel",
    "component.sizetable.size": "Maat",
    "component.sizetable.chest": "Borst",
    "component.sizetable.waist": "Taille",
    "component.sizetable.hip": "Heupen",

    "components.bancontact-payment.account-holders-name": "Naam op kaart",
    "components.bancontact-payment.start-payment": "Start betaling",
    "components.bancontact-payment.payment-ok-wait": "Ok, even geduld aub...",

    "components.sofort-payment.next": "volgende",

    "components.checkout-cart-overview.items-overview": "Klik voor overzicht",
    "components.checkout-cart-overview.shipping": "Levering",
    "components.checkout-cart-overview.free-try": "Gratis passen",
    "components.checkout-cart-overview.shipping-amount": "Gratis",
    "components.checkout-cart-overview.total": "Totaal",

    "components.credit-card-payment.submit": "Betaal",

    "components.shopping-cart-overview-item.size": "Maat",
    "components.shopping-cart-overview-item.amount": "Aantal",
    "components.shopping-cart-overview-item.free-try": "gratis passen",

    "components.shopping-cart-overview-row.size": "Maat",
    "components.shopping-cart-overview-row.amount": "Aantal",
    "components.shopping-cart-overview-row.free-try": "gratis passen",

    "components.error-modal.the-error-message-is": "Het foutbericht is",
    "components.error-modal.if-you-dont-know": "Als je niet zeker bent hoe je verder moet, stuur ons even een e-mail: hello@fabbrikka.com",

    "components.cookies-dialog.this-site-uses-cookies": "De EU vraagt ons om je even te melden dat onze website cookies gebruikt.",
    "components.cookies-dialog.for-more-info-click": "Voor meer info over onze lekkere cookies, klik",
    "components.cookies-dialog.here": "hier.",

    "components.voucher-payment.voucher-name": "cadeaubon",
    "components.voucher-payment.voucher-code": "geef code cadeaubon in",

    "templates.application.headbanner": "DUURZAME KLEDING - GRATIS VERZENDING",

    "templates.shopping-cart.checkout.checkout": "Betaling",
    "templates.shopping-cart.checkout.delivery-address": "Leveradres",
    "templates.shopping-cart.checkout.name": "Volledige naam",
    "templates.shopping-cart.checkout.email": "Email",
    "templates.shopping-cart.checkout.street": "Straat",
    "templates.shopping-cart.checkout.number-bus": "Nummer",
    "templates.shopping-cart.checkout.city-country": "Stad",
    "templates.shopping-cart.checkout.zip": "Postcode",
    "templates.shopping-cart.checkout.country": "Land",
    "templates.shopping-cart.checkout.order-summary": "Overzicht bestelling",
    "templates.shopping-cart.checkout.payment": "Betaling - Powered by",
    "templates.shopping-cart.checkout.payment-prompt": "Kies een betaalmethode",
    "templates.shopping-cart.checkout.error-title": "Oh een foutje...",
    "templates.shopping-cart.checkout.order-confirmation.title": "Bedankt voor je bestelling",
    "templates.shopping-cart.checkout.order-confirmation.order-id-title": "Je bestelnummer",
    "templates.shopping-cart.checkout.order-confirmation.email-sent-to": "We hebben een email verstuurd naar",
    "templates.shopping-cart.checkout.order-confirmation.in-case-of-issues": "In geval van problemen kan je steeds terecht bij",
    "templates.shopping-cart.checkout.order-confirmation.love-helping": "We helpen je graag",
    "templates.shopping-cart.checkout.order-confirmation.back": "Terug naar het begin",
    "templates.shopping-cart.checkout.choose-country": "Land",
    "templates.shopping-cart.checkout.country-not-in-list": "Vragen over je maat, onze werkwijze of het weer: ",
    "templates.shopping-cart.checkout.delivery-time": "Je pakketje komt binnen de 5 werkdagen aan.",
    "templates.shopping-cart.checkout.free-try-warning": "Bij gratis passen alleen betaling per credit card mogelijk.",

    "controllers.shopping-cart.countries.belgium": "Belgie",
    "controllers.shopping-cart.countries.spain": "Spanje",
    "controllers.shopping-cart.countries.france": "Frankrijk",
    "controllers.shopping-cart.countries.netherlands": "Nederland",
    "controllers.shopping-cart.countries.germany": "Duitsland",
    "controllers.shopping-cart.countries.italy": "Italie",
    "controllers.shopping-cart.countries.uk": "Verenigd Koninkrijk",
    "controllers.shopping-cart.countries.austria": "Oostenrijk",
    "controllers.shopping-cart.countries.switzerland": "Zwitserland",

    "templates.about.disclaimer": "Fabbrikka is eigendom van bvba Megenfelixendekat - Witte Patersstraat 4 - 1040 Brussel - btw: BE0675410010",

    "templates.shopping-cart.index.title": "Winkelmandje",
    "templates.shopping-cart.index.item": "Artikel",
    "templates.shopping-cart.index.size": "Maat",
    "templates.shopping-cart.index.units": "Aantal",
    "templates.shopping-cart.index.subtotal": "Subtotaal artikels",
    "templates.shopping-cart.index.shipping": "Levering",
    "templates.shopping-cart.index.shipping-cost": "Gratis",
    "templates.shopping-cart.index.grand-total": "Totaal",
    "templates.shopping-cart.index.shop-more": "Meer shoppen",
    "templates.shopping-cart.index.checkout": "Uitchecken",
    "templates.shopping-cart.index.empty-cart": "Oeps, je winkelmandje is nog leeg",

    "controllers.shopping-cart.index.errors.wrong-email": "Bedankt om een geldig e-mailadres op te geven :-)",
    "controllers.shopping-cart.index.errors.required": "Verplicht...",

    "controllers.shopping-cart.checkout.errors.general": "Er zijn fouten in de adresgegevens",
    "controllers.shopping-cart.checkout.payment.error-message": "Fout bij het verwerken van de betaling:"

  };
});