define('fabbrikka-frontend/initializers/shopping-cart', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'cart', 'service:shopping-cart');
  }

  exports['default'] = {
    name: 'shopping-cart',
    initialize: initialize
  };
});