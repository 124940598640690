define('fabbrikka-frontend/services/locale-tracker', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Service.extend({
        defaultLocale: 'en-gb',
        multiLocalesUser: false,
        multiLocalesUserAvailibleLocales: [],
        i18n: _ember['default'].inject.service(),
        cookies: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        fastboot: _ember['default'].inject.service(),

        locale: _ember['default'].computed.reads('i18n.locale'),
        countryCode: "gb",

        init: function init() {
            //first check if was set previously
            this._super.apply(this, arguments);

            this._setCountry();

            if (this._hasUserSetLocale()) {
                this.setLocale(this._fetchLocaleFromCookie());
                return;
            }

            //check if specified in url
            if (this._hasLocalefromURL()) {
                this.setLocale(this._fetchLocaleFromURL());
                return;
            }

            //ok nothing found set the default here
            this.set('i18n.locale', this.get('defaultLocale'));

            //last resort, let's see whether the locale guesser can tell us something
            this._setLocaleFromRemote();
        },

        setLocale: function setLocale(locale) {
            var locales = this.get("i18n").get('locales');
            var selectedLocale = locales.includes(locale) ? locale : this.get('defaultLocale');

            this.set('i18n.locale', selectedLocale);
            var expiration_date = new Date();
            expiration_date.setFullYear(expiration_date.getFullYear() + 1);
            this.get('cookies').write("locale-user", selectedLocale, { expires: expiration_date, path: "/" });
        },

        getLocale: function getLocale() {
            return this.get('i18n.locale');
        },

        _fetchLocaleFromCookie: function _fetchLocaleFromCookie() {
            return this.get('cookies').read("locale-user");
        },

        _fetchLocaleFromRemote: function _fetchLocaleFromRemote() {
            return this.get('ajax').request(_fabbrikkaFrontendConfigEnvironment['default'].APP.localeGuesser + "/locales");
        },

        _fetchLocaleFromURL: function _fetchLocaleFromURL() {
            ////////////////////////////////////////////////////////////////////////
            //TODO: HAKING ALERT!!! find out what the proper way is...
            ////////////////////////////////////////////////////////////////////////
            console.log("WARNING: locale-tracker.js still contains a hack!");
            try {
                if (this._hasLocationSet()) {
                    var queryParams = location.search.substring(1);
                    var routerInstance = _ember['default'].getOwner(this).lookup('router:main');
                    if (queryParams.length > 0) {
                        var query = routerInstance.router.recognizer.parseQueryString(queryParams);
                        return query && query["locale"] || null;
                    }
                } else {
                    var parameters = this.get('fastboot.request.queryParams');
                    if (Object.keys(parameters).length !== 0) {
                        return this.get(parameters)["locale"];
                    }
                }
            } catch (err) {
                console.log(err.message);
                return null;
            }
        },

        _hasLocationSet: function _hasLocationSet() {
            if (typeof location === 'undefined' || location === null) {
                return false;
            }
            return true;
        },

        _hasLocalefromURL: function _hasLocalefromURL() {
            var locale = this._fetchLocaleFromURL();
            if (locale && locale.length !== 0) {
                return true;
            }
            return false;
        },

        _hasUserSetLocale: function _hasUserSetLocale() {
            var userLocale = this._fetchLocaleFromCookie();
            if (userLocale && userLocale.length !== 0) {
                return true;
            }
            return false;
        },

        _setLocaleFromRemote: function _setLocaleFromRemote() {
            var _this = this;

            this._fetchLocaleFromRemote().then(function (locales) {

                if (locales.length === 0) {
                    return;
                }

                if (_this._hasUserSetLocale()) {
                    //user could have set it while waiting
                    return;
                }

                if (locales.length > 1) {
                    //some consumging component can do something with this info
                    _this.set('multiLocalesUserAvailibleLocales', locales);
                    _this.set('multiLocalesUser', true);
                }

                _this.setLocale(locales[0]);
            });
        },

        _setCountry: function _setCountry() {
            var _this2 = this;

            this._fetchLocaleFromRemote().then(function (locales) {
                if (locales.length === 0) {
                    return;
                }
                _this2.set('countryCode', locales[0].split('-')[1] || 'gb');
            });
        }

    });
});