define('fabbrikka-frontend/routes/admin/stock-items/index', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        model: function model() {
            return this.store.query('stock-item', { include: 'product-variant,product-variant.product,' + 'product-variant.product.product-images,product-variant.size,' + 'product-variant.product.product-names',
                page: { size: 1000 } });
        },

        actions: {
            update: function update(stockItem) {
                stockItem.save();
            },
            'delete': function _delete(stockItem) {
                stockItem.destroyRecord();
            }
        }
    });
});