define('fabbrikka-frontend/initializers/start-locale-tracker', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('component', 'locale-select-modal', 'service:locale-tracker');
  }

  exports['default'] = {
    name: 'start-locale-tracker',
    before: 'shopping-cart',
    initialize: initialize
  };
});