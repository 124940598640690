define('fabbrikka-frontend/routes/application', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({
        fastboot: _ember['default'].inject.service(),
        actions: {
            loading: function loading(transition) {
                var controller = this.controllerFor('application');
                controller.set('currentlyLoading', true);
                transition.promise['finally'](function () {
                    controller.set('currentlyLoading', false);
                });
            }
        }
    });
});