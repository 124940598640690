define('fabbrikka-frontend/routes/admin/faq/index', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.store.query('faq', { include: 'faq-contents' });
    },

    actions: {
      'delete': function _delete(faqItem) {
        faqItem.get("faqContents").forEach(function (item) {
          item.destroyRecord();
        });
        faqItem.destroyRecord();
      },

      edit: function edit(faqItem) {
        this.transitionTo('admin.faq.edit', faqItem.get("id"));
      }
    }
  });
});