define('fabbrikka-frontend/components/voucher-payment', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        i18n: _ember['default'].inject.service(),
        ajax: _ember['default'].inject.service(),
        stripeService: _ember['default'].inject.service(),
        isSubmittingPayment: false,

        model: { voucherCode: "" },
        errors: {},

        billingData: null,

        //Validation stuff, literal copy paste from checkout. need to abstract this away...
        accountHolderNameEmptyObserver: _ember['default'].observer('model.voucherCode', function () {
            this.validateEmptyField('voucherCode');
        }),

        validateEmptyField: function validateEmptyField(key) {
            var hasErrors = false;
            var thisModel = this.get('model');

            if (!thisModel[key]) {
                this.set('errors.' + key, [this.get("i18n").t('controllers.shopping-cart.index.errors.required')]);
                hasErrors = true;
            } else {
                this.set('errors.' + key, []);
            }
            return hasErrors;
        },

        validateForm: function validateForm() {
            var hasErrors = false;
            var thisModel = this.get('model');
            var keys = Object.keys(thisModel);

            for (var i = 0; i < keys.length; i++) {
                hasErrors = this.validateEmptyField(keys[i]);
            }

            return hasErrors;
        },

        submitPaymentToPaymentService: function submitPaymentToPaymentService() {
            var checkoutData = { "billingData": this.get("billingData"),
                tokenData: {
                    token: {
                        id: this.get('model.voucherCode'),
                        card: { brand: 'voucher' } } } };
            return this.get('ajax').request(_fabbrikkaFrontendConfigEnvironment['default'].APP.checkoutService + '/checkouts', {
                method: 'POST',
                data: JSON.stringify(checkoutData),
                contentType: 'application/vnd.api+json'
            });
        },
        actions: {
            submitPayment: function submitPayment() {
                var self = this;
                self.set('isSubmittingPayment', true);
                self.get('onPay')().then(function (billingData) {
                    if (self.validateForm()) {
                        return _ember['default'].RSVP.Promise.reject({ "type": "voucherFormIssue" });
                    }
                    self.set('billingData', billingData);
                    return true;
                }).then(self.submitPaymentToPaymentService.bind(self)).then(function (data) {
                    self.set('isSubmittingPayment', false);
                    return self.get('onPaySuccess')(data);
                })['catch'](function (error) {
                    self.set('isSubmittingPayment', false);
                    if (error && error["type"] === "voucherFormIssue") {
                        return;
                    }
                    return self.get('onPayError')(error);
                });
            }
        }
    });
});