define('fabbrikka-frontend/components/fabb-loading-hider', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.$('.fabb-loading-hider-almost').css('visibility', 'visible');
      this.$('.fabb-loading-hider').delay(500).fadeOut(700);
      this.$('.fabb-loading-hider').scrollTop();
    }
  });
});