define("fabbrikka-frontend/controllers/admin/faq/index", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Controller.extend({
    localeTracker: _ember["default"].inject.service(),
    locale: _ember["default"].computed.reads("localeTracker.locale"),
    filteredFaqEntries: _ember["default"].computed("locale", "model", function () {
      return this.get("model").forEach(function (item) {
        return item; //TODO: needs locale filter
      });
    })

  });
});