define('fabbrikka-frontend/routes/admin/stock-items/new', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Route.extend({

        model: function model() {
            return _ember['default'].RSVP.hash({
                "stockItems": this.store.query('stock-item', {
                    include: 'product-variant',
                    page: {
                        size: 1000
                    }
                }),

                //"stockItems": this.store.findAll('stock-item', {include: 'product-variant'}),
                //This is a nasty workaround because couldn't make the include work
                "products": this.store.findAll('product', { include: "product-images,product-variants,product-names,product-descriptions,product-variants.size" })
            });
        },

        afterModel: function afterModel(model) {
            //filter the ones which already have stock information
            //note nasty workaround
            var productVariantsWithoutStock = model.products.reduce(function (acc, item) {
                acc.pushObjects(item.get('productVariants').toArray());
                return acc;
            }, []).filter(function (productVariant) {
                var stockItem = model.stockItems.find(function (item) {
                    return item.get('productVariant').get('id') === productVariant.get('id');
                });
                return !stockItem;
            });

            model.products = productVariantsWithoutStock.map(function (item) {
                return { 'id': item.get('id'),
                    'name': "name: " + item.get('product').get('productNames').get('firstObject').get('name') + ", size : " + item.get('size').get('name') };
            }) || [];
        },

        actions: {
            save: function save(model) {
                var self = this;
                self.store.findRecord('product-variant', model.stockVariant).then(function (productVariant) {
                    return self.get('store').createRecord('stock-item', { quantity: model.stockAmount,
                        productVariant: productVariant }).save();
                }).then(function () {
                    self.transitionTo("admin.stock-items");
                })['catch'](function () {
                    alert("Creation of stock item failed");
                });
            },
            deactivate: function deactivate() {
                var model = this.controllerFor('admin.stock-items.new').get('model');
                model.rollbackAttributes();
            }
        }
    });
});