define('fabbrikka-frontend/components/navbar-mat-fabb', ['exports', 'ember'], function (exports, _ember) {
    exports['default'] = _ember['default'].Component.extend({
        cartService: _ember['default'].inject.service('shopping-cart'),
        isFreeTryOutAllowed: _ember['default'].computed.reads('cartService.isFreeTryOutAllowed'),
        session: _ember['default'].inject.service(),
        didInsertElement: function didInsertElement() {
            this.$(".button-collapse").sideNav({ closeOnClick: true, menuWidth: 200 });
        },
        didRender: function didRender() {
            this.$(".dropdown-button").dropdown();
        }
    });
});