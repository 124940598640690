define('fabbrikka-frontend/components/md-input', ['exports', 'ember'], function (exports, _ember) {
  var Component = _ember['default'].Component;
  var computed = _ember['default'].computed;
  var isPresent = _ember['default'].isPresent;
  exports['default'] = Component.extend({
    layout: null,
    type: 'text',
    labelIsActive: false,
    classNames: ['input-field'],

    bindAttributes: ['disabled', 'readonly', 'autofocus'],
    validate: false,
    _wasTouched: false,
    isValid: computed('_wasTouched', 'value', 'validate', 'errors', 'errors.[]', function () {
      return (isPresent(this.get('value')) || this.get('_wasTouched')) && this.get('validate') && this.get('errors') && this.get('errors.length') === 0;
    }),

    isInvalid: computed('_wasTouched', 'value', 'validate', 'errors', 'errors.[]', function () {
      return this.get('validate') && this.get('errors') && this.get('errors.length') > 0;
    }),

    isActiveLabel: _ember['default'].observer('value', 'errors', 'errors.[]', function () {
      var $label = this.$('> label');
      $label.addClass('active'); //as soon as something happens on the field it is considered active
      this.set('labelIsActive', true);
    }),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      // pad the errors element when an icon is present
      if (isPresent(this.get('icon'))) {
        this.$('> span').css('padding-left', '3rem');
      }
      this._setupLabel();
    },

    id: computed('elementId', function () {
      return this.get('elementId') + '-input';
    }),

    _setupLabel: function _setupLabel() {
      var $label = this.$('> label');
      if (isPresent(this.get('value')) && !$label.hasClass('active') || this.get('isInvalid')) {
        $label.addClass('active');
      }
    },
    _errorString: computed('errors.[]', function () {
      return (this.get('errors') || []).join('. ');
    }),
    actions: {
      inputFocusIn: function inputFocusIn(evt) {
        this.set('_wasTouched', true);
        this.sendAction('focusIn', evt);
      },
      inputFocusOut: function inputFocusOut() {
        if (this.get('isInvalid')) {
          return false;
        }
      }

    }
  });
});