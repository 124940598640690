define('fabbrikka-frontend/components/sofort-payment', ['exports', 'ember', 'fabbrikka-frontend/config/environment', 'fabbrikka-frontend/components/bancontact-payment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment, _fabbrikkaFrontendComponentsBancontactPayment) {
  exports['default'] = _fabbrikkaFrontendComponentsBancontactPayment['default'].extend({
    i18n: _ember['default'].inject.service(),
    paymentType: "sofort",
    availibleCountries: [],

    model: { selectedCountry: null },

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model.selectedCountry', this.get('selectedCountry'));
      this.set('availibleCountries', [{
        "code": "BE",
        "name": this.get("i18n").t('controllers.shopping-cart.countries.belgium')
      }, {
        "code": "DE",
        "name": this.get("i18n").t('controllers.shopping-cart.countries.germany')
      }, {
        "code": "IT",
        "name": this.get("i18n").t('controllers.shopping-cart.countries.italy')
      }, {
        "code": "NL",
        "name": this.get("i18n").t('controllers.shopping-cart.countries.netherlands')
      }, {
        "code": "AT",
        "name": this.get("i18n").t('controllers.shopping-cart.countries.austria')
      }, {
        "code": "ES",
        "name": this.get("i18n").t('controllers.shopping-cart.countries.spain')
      }]);
    },

    actions: {
      submitPayment: function submitPayment() {
        var _this = this;

        var self = this;
        self.set('isSubmittingPayment', true);
        self.get('onPay')().then(function (billingData) {
          if (self.validateForm()) {
            return _ember['default'].RSVP.Promise.reject({ "type": "bancontactFormIssue" });
          }

          billingData['paymentType'] = self.get("paymentType");
          billingData['sofortCountry'] = _this.get('model.selectedCountry');

          //TODO: this is a temporary workaround, the model should not be passed around through queries. But no time sorry
          var redirectUrl = _fabbrikkaFrontendConfigEnvironment['default'].APP.publicHostName + _ember['default'].getOwner(self).lookup('controller:application').target.currentURL;
          redirectUrl = redirectUrl + "?" + _ember['default'].$.param(billingData);

          //set data object
          var paymentData = {
            type: self.get('paymentType'),
            amount: Math.floor(self.get('totalAmount') * 100),
            currency: 'eur',
            sofort: {
              country: _this.get('model.selectedCountry')
            },
            redirect: {
              return_url: redirectUrl
            }
          };

          return self.get('stripeService').initPayment(paymentData);
        })['catch'](function (error) {
          self.set('isSubmittingPayment', false);
          if (error && error["type"] === "bancontactFormIssue") {
            return;
          }
          return self.get('onPayError')(error);
        });
      }
    }
  });
});