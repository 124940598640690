define('fabbrikka-frontend/routes/admin/product-audiences/new', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    store: _ember['default'].inject.service(),
    model: function model() {
      return this.get('store').createRecord('product-audience');
    },
    actions: {
      save: function save(model) {
        var self = this;
        return model.save().then(function () /*model*/{
          self.transitionTo("admin.product-audiences");
        })['catch'](function () {
          alert("Creation of product-audience failed");
        });
      },
      deactivate: function deactivate() {
        var model = this.controllerFor('admin.product-audiences.new').get('model');
        model.rollbackAttributes();
      }
    }
  });
});