define('fabbrikka-frontend/controllers/shopping-cart/checkout', ['exports', 'ember'], function (exports, _ember) {
    var _this = this;

    // WORKAROUND: see https://github.com/mike-north/ember-materialize-shim/issues/80
    // import Materialize from 'materialize';

    exports['default'] = _ember['default'].Controller.extend({
        //TODO: this should be re-thought. Basically the flow of bancontact works with a redirect.....
        queryParams: {
            clientSecretQP: 'client_secret',
            sourceQP: 'source',
            cityQP: 'deliveryAddress[city]',
            countryQP: 'deliveryAddress[country]',
            emailQP: 'deliveryAddress[email]',
            houseNumberQP: 'deliveryAddress[houseNumber]',
            nameQP: 'deliveryAddress[name]',
            streetQP: 'deliveryAddress[street]',
            zipQP: 'deliveryAddress[zip]',
            paymentTypeQP: 'paymentType',
            sofortCountryQP: 'sofortCountry'
        },
        clientSecretQP: null,
        sourceQP: null,
        cityQP: null,
        emailQP: null,
        houseNumberQP: null,
        nameQP: null,
        streetQP: null,
        zipQP: null,
        countryQP: null,
        paymentTypeQP: null,
        sofortCountryQP: null,

        clientSecretObserver: _ember['default'].observer('clientSecretQP', function () {
            if (!this.get('clientSecretQP')) {
                return;
            }
            this.set('model', {
                name: this.get('nameQP'),
                email: this.get('emailQP'),
                street: this.get('streetQP'),
                houseNumber: this.get('houseNumberQP'),
                city: this.get('cityQP'),
                zip: this.get('zipQP'),
                country: this.get('countryQP'),
                paymentType: this.get('paymentTypeQP'),
                sofortCountry: this.get('sofortCountryQP')
            });
            this.set('chosenPaymentMethod', this.get('paymentTypeQP'));
        }),

        localeTracker: _ember['default'].inject.service(),
        locale: _ember['default'].computed.reads("localeTracker.locale"),
        cartService: _ember['default'].inject.service('shopping-cart'),
        scroller: _ember['default'].inject.service(),
        i18n: _ember['default'].inject.service(),
        totalAmount: _ember['default'].computed.reads('cartService.total'),
        totalFreeTries: _ember['default'].computed.reads('cartService.totalFreeTries'),
        hasFreeTries: _ember['default'].computed.reads('cartService.totalFreeTries', function () {
            return _this.get('totalFreeTries') > 0 ? true : false;
        }),
        model: { name: "", email: "", street: "", houseNumber: "", city: "", zip: "", country: "" },
        errors: {},

        availibleCountries: _ember['default'].computed(function () {
            var countries = [{
                "code": "BE",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.belgium')
            }, {
                "code": "FR",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.france')
            }, {
                "code": "ES",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.spain')
            }, {
                "code": "NL",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.netherlands')
            }, {
                "code": "DE",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.germany')
            }, {
                "code": "AT",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.austria')
            }, {
                "code": "GB",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.uk')
            }, {
                "code": "IT",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.italy')
            }, {
                "code": "CH",
                "name": this.get("i18n").t('controllers.shopping-cart.countries.switzerland')
            }];

            if (this.get('cartService.totalFreeTries') > 0) {
                return [{
                    "code": "BE",
                    "name": this.get("i18n").t('controllers.shopping-cart.countries.belgium')
                }, {
                    "code": "NL",
                    "name": this.get("i18n").t('controllers.shopping-cart.countries.netherlands')
                }];
            }

            return countries.sort(function (a, b) {
                return a.name > b.name;
            });
        }).property('cartService.totalFreeTries'),

        paymentMethods: _ember['default'].computed(function () {
            var paymentMethods = [{ name: "visa/mastercard", value: "visa" }, { name: "bancontact", value: "bancontact" }, { name: "sofort", value: "sofort" }, { name: "ideal", value: "ideal" }, { name: this.get('i18n').t('components.voucher-payment.voucher-name'), value: "voucher" }];
            if (this.get('cartService.totalFreeTries') > 0) {
                return [{ name: "visa/mastercard", value: "visa" }];
            }
            return paymentMethods;
        }).property('cartService.totalFreeTries'),

        emailDidChange: _ember['default'].observer('model.email', function () {
            var messages = [];
            if (!this.validateEmail(this.get('model.email'))) {
                messages = [this.get("i18n").t('controllers.shopping-cart.index.errors.wrong-email')];
            }
            this.set('errors.email', messages);
        }),

        nameEmptyObserver: _ember['default'].observer('model.name', function () {
            this.validateEmptyField('name');
        }),

        emailEmptyObserver: _ember['default'].observer('model.email', function () {
            this.validateEmptyField('email');
        }),

        streetEmptyObserver: _ember['default'].observer('model.street', function () {
            this.validateEmptyField('street');
        }),

        houseNumberEmptyObserver: _ember['default'].observer('model.houseNumber', function () {
            this.validateEmptyField('houseNumber');
        }),

        cityEmptyObserver: _ember['default'].observer('model.city', function () {
            this.validateEmptyField('city');
        }),

        zipEmptyObserver: _ember['default'].observer('model.zip', function () {
            this.validateEmptyField('zip');
        }),

        countryEmptyObserver: _ember['default'].observer('model.country', function () {
            this.validateEmptyField('country');
        }),

        validateEmptyField: function validateEmptyField(key) {
            var hasErrors = false;
            var thisModel = this.get('model');

            if (!thisModel[key]) {
                this.set('errors.' + key, [this.get("i18n").t('controllers.shopping-cart.index.errors.required')]);
                hasErrors = true;
            } else {
                this.set('errors.' + key, []);
            }
            return hasErrors;
        },

        validateEmail: function validateEmail(email) {
            var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        extractBackendErrorMsg: function extractBackendErrorMsg(error) {
            var errorMsg = error && error.errors && error.errors[0] && error.errors[0].detail && error.errors[0].detail.message;
            if (!errorMsg) {
                return error["message"] || "general error";
            }
            return errorMsg;
        },

        scrollToTop: function scrollToTop() {
            this.get('scroller').scrollVertical(_ember['default'].$('h2').first(), { duration: 1000, easing: 'linear' });
        },

        validateForm: function validateForm() {

            //check all required fields are there
            var hasErrors = false;
            var thisModel = this.get('model');

            //TODO: REFACTOR THIS NASTY SHIT
            if (thisModel.paymentType !== 'sofort') {
                delete thisModel.sofortCountry;
            }

            var keys = Object.keys(thisModel);

            for (var i = 0; i < keys.length; i++) {
                hasErrors = this.validateEmptyField(keys[i]);
            }

            //check mail again
            if (!this.validateEmail(this.get('model.email'))) {
                var messages = [this.get("i18n").t('controllers.shopping-cart.index.errors.wrong-email')];
                this.set('errors.email', messages);
            }

            if (hasErrors) {
                this.scrollToTop();
            }

            //additional help to visualize in small screens, since in firefox scrolling does not work
            if (hasErrors && this.get('media.isS')) {
                var errorText = this.get("i18n").t('controllers.shopping-cart.checkout.errors.general');
                Materialize.toast(errorText, 2000, 'checkout-error-toast');
            }

            return hasErrors;
        },

        actions: {

            onSubmitPayment: function onSubmitPayment() {
                var self = this;
                return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                    if (self.validateForm()) {
                        reject();
                    }
                    resolve({
                        "locale": self.get("locale"),
                        "cartId": self.get("cartService").get('cart').get('id'),
                        "deliveryAddress": self.get('model')
                    });
                });
            },

            onSubmitPaymentError: function onSubmitPaymentError(data) {
                var message = this.get("i18n").t('controllers.shopping-cart.checkout.payment.error-message') + " " + this.extractBackendErrorMsg(data);
                this.set('backendErrorText', message);
                this.set('hasBackendError', true);
            },

            onSubmitPaymentSuccess: function onSubmitPaymentSuccess(data) {
                var _this2 = this;

                this.get("cartService").resetCart().then(function () {
                    _this2.set("orderConfirmation", data);
                    _this2.set("orderConfirmed", true);
                    _this2.scrollToTop();
                });
            }
        }

    });
});