define('fabbrikka-frontend/components/credit-card-payment', ['exports', 'ember', 'fabbrikka-frontend/config/environment'], function (exports, _ember, _fabbrikkaFrontendConfigEnvironment) {
    exports['default'] = _ember['default'].Component.extend({
        card: null,
        cardMounted: false,
        creditCardElement: "credit-card-element",
        creditCardErrors: "credit-card-errors",
        billingData: null,
        ajax: _ember['default'].inject.service(),
        stripeService: _ember['default'].inject.service(),
        isSubmittingPayment: false,

        didInsertElement: function didInsertElement() {
            this._super.apply(this, arguments);
            this.mountCart();
        },

        mountCart: function mountCart() {
            // Create an instance of the card Element
            var card = this.get('stripeService').initStripeElementsCard();

            // Add an instance of the card Element into the `card-element` <div>
            card.mount('#' + this.get('creditCardElement'));

            // Handle real-time validation errors from the card Element.
            var self = this;
            card.addEventListener('change', function (event) {
                var displayError = document.getElementById(self.get('creditCardErrors'));
                if (event.error) {
                    displayError.textContent = event.error.message;
                } else {
                    displayError.textContent = '';
                }
            });

            this.set('card', card);
            this.set('cardMounted', true);
        },

        handleTokenFetch: function handleTokenFetch(tokenFetchResult) {
            var _this = this;

            return new _ember['default'].RSVP.Promise(function (resolve, reject) {
                if (tokenFetchResult.error) {
                    var errorElement = document.getElementById(_this.get('creditCardErrors'));
                    errorElement.textContent = tokenFetchResult.error.message;
                    return reject({ "type": "tokenFetchError", "error": "tokenFetchResult.error.message" });
                }
                resolve(tokenFetchResult);
            });
        },

        submitPaymentToPaymentService: function submitPaymentToPaymentService(tokenData) {
            var checkoutData = { 'tokenData': tokenData, "billingData": this.get("billingData") };
            return this.get('ajax').request(_fabbrikkaFrontendConfigEnvironment['default'].APP.checkoutService + '/checkouts', {
                method: 'POST',
                data: JSON.stringify(checkoutData),
                contentType: 'application/vnd.api+json'
            });
        },

        actions: {
            submitPayment: function submitPayment() {
                var self = this;
                self.set('isSubmittingPayment', true);
                self.get('onPay')().then(function (billingData) {
                    self.set('billingData', billingData);
                    return self.get('stripeService').get('stripeInstance').createToken(self.get('card'));
                }).then(self.handleTokenFetch.bind(self)).then(self.submitPaymentToPaymentService.bind(self)).then(function (data) {
                    self.set('isSubmittingPayment', false);
                    return self.get('onPaySuccess')(data);
                })['catch'](function (error) {
                    self.set('isSubmittingPayment', false);
                    if (error && error["type"] === "tokenFetchError") {
                        return;
                    }
                    return self.get('onPayError')(error);
                });
            }
        }
    });
});